// import upArrow from '../assets/img/up-arrow.png';
// import downArrow from '../assets/img/down-arrow.png';
const index = {
  table_body: {
    marginTop: '16px'
  },
  table_size: {
    background: 'none',
    border: 'none',
    padding: 0
  },
  align_position: {
    position: 'relative',
    top: '-6px'
  },
  table_size_dropdown: {
    width: '70px',
    flex: 'none',
    margin: '0px 10px',
    display: 'inline-block',
    float: 'none'
  },
  table_filter: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '5px',
    width: '280px'
  },
  table_tool: {
    display: 'inline-block',
    verticalAlign: 'top'
  },
  table_tool_btn: {
    marginRight: '5px'
  },
  padd_btm: {
    paddingBottom: '12px'
  }
  , custdropdown: {
    height: '31px',
    // lineHeight: '0.5'
    padding: '0px 4px'
  }, flexClass: {
    display: 'inline-block',
    padding:'2px'
  },
  history_btnClass: {
    display: 'inline-block',
    top: '-5px'
  }
};

export default index;